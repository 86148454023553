.contactFormContainer {
    padding-inline: 14%;
    padding-block: 5%;

    >div {
        text-align: center;

        h4 {
            color: #37393F;
            font-weight: 700;
            font-size: 2rem;
            margin-block: 1rem;
        }

        p {
            display: none;
        }
    }

    .contactForm {
        display: flex;
        justify-content: space-between;

        >div {
            width: 49.5%;

            >div {
                margin-block: 1rem;
                background-color: #DDDDDD;
                color: #37393F;
            }
        }

    }

    .textMessage {
        resize: none;
        width: 98%;
        background-color: #F8F8F8;
        padding: 1%;
        border-radius: 0.15rem;
    }

    .textMessage+div {
        margin-block-start: 2rem;

        @media screen and (max-width:480px) {
            margin-block: 1rem;

        }

        button {
            background-color: #6B77E5;
            color: #ffffff;
        }
    }
}