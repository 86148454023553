.commonSectionContainer {
    padding-inline: 16%;
    padding-block: 5%;

    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

}

@media screen and (max-width:480px) {
    .commonSectionContainer {
        padding-inline: 8%;
        padding-block: 0%;
        display: flex;

        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
            display: none;
        }

    }

}