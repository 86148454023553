.marketingStrategyContainer {
    padding-inline: 8%;
    padding-block: 4%;

    >div:nth-child(1) {
        padding-block-end: 3%;

        @media screen and (max-width:480px) {
            padding-block: 6%;
            padding-block-end: 8%;

        }
    }

    .cardContainer {
        display: flex;
        justify-content: space-between;

        .card {
            // width: 32%;

            h4,
            p {
                padding-inline-end: 4%;
                padding-block: 1.5%;
            }

            img {
                width: 100%;
                width: 100%;
                object-fit: fill;
            }
        }
    }
}