.simpleButtonContainer {
    button {
        background-color: #F05836;
        color: #ffffff;
        padding-block: 0.5rem;
        padding-inline: 2rem;

        &:hover {
            border: 1px solid #ffffff;
        }
    }

}