.aboutUsSectionContainer {
    display: flex;
    justify-content: space-between;

    .leftSide {
        width: 45%;
        padding-inline-start: 12%;
        padding-block: 5%;
        background-color: #EAECF0;

        >div {
            width: 90%;

            img {
                width: 100%;
                height: inherit;
                object-fit: cover;
            }
        }
    }

    .rightSide {
        width: 55%;
        padding-inline-end: 12%;
        padding-block: 5%;
        padding-inline-start: 2.5%;

        >div:nth-child(1) {
            h4 {
                margin-block: 0.8rem;
            }

            p {
                display: none;
            }

            margin-block-end: 2rem;
        }

        >div:nth-last-child(3) {
            margin-block-start: 4rem !important;
        }

        >div:nth-last-child(2),
        >div:nth-last-child(3) {
            padding-block: 4%;
            padding-inline: 6%;
            margin-block: 1rem;
            background-color: #E3E3E3;
        }

        >div:nth-last-child(1) {
            margin-block-start: 2rem;

            button {
                background-color: #6B77E5;
                color: #ffffff;

            }
        }

    }
}

@media screen and (max-width:480px) {
    .aboutUsSectionContainer {
        display: flex;
        flex-direction: column !important;

        .leftSide {
            width: 100%;
        }

        .rightSide {
            width: 100%;
            padding-inline: 10%;
            padding-block-start: 2rem;
        }
    }

}