.carrerContainer {


    >div:nth-child(2) {
        text-align: center;
        padding-inline: 15%;
        padding-block-start: 5%;


        h4 {
            color: #101828;
            font-size: 2rem;
            margin-block: 0.8rem;
            font-weight: 700;
        }

        p {
            border-bottom: unset;
            text-align: center;
            width: 100%;
            color: #667085;
        }
    }

    .carrerForm {
        padding-inline: 15%;
        padding-block-end: 5%;



        >div {
            display: flex;
            justify-content: space-between;


            >div {
                width: 49.5%;

                >div {
                    margin-block: 0.8rem;
                }

                .selectFile {
                    width: 100%;
                    margin-block-start: 0rem;
                    @media screen and (max-width:480px) {
                        width: 200%;
                        
                    }

                    >div {
                        height: 2.5rem;
                    }
                }
            }
        }

        textarea {
            resize: none;
            width: 98%;
            background-color: #F8F8F8;
            padding: 1%;
            border-radius: 0.14rem;
        }

        textarea+div {
            display: flex;
            justify-content: center;
            margin-block-start: 1rem;
            @media screen and (max-width:480px) {
                margin-block: 1rem;
                
            }

            button {
                color: #ffffff;
                background-color: #6B77E5;
                border-radius: unset;
            }
        }
    }
}