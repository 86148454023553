.weProvideContainer {
    text-align: center;
    padding-inline: 8%;

    .cardContainer {
        margin-block-start: 2.5rem;
    }

    .cardContainer,
    .cardContainer2 {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .card {
            width: 32.5%;
            padding: 2%;
            background-color: #F4F4F4;
            margin-block-end: 1rem;

            h4,
            span {
                text-align: left;
            }

            h5 {
                padding-block-start: 2rem;
            }

            img {
                // background-color: #40DDB6;
                // padding: 0.6rem;
                // border-radius: 50%;
            }
        }

        .more {
            width: 32.5%;
            padding: 4%;
            background-color: #6B77E5;
            margin-block-end: 1rem;
        }
    }

    .responsiveCardContainer {
        display: none;
    }

}

@media screen and (max-width:480px) {
    .weProvideContainer {
        margin-block-start: 4rem;

        .cardContainer,
        .cardContainer2 {
            display: none;
        }

        .responsiveCardContainer {
            display: block;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .card {
                width: 100% !important;
                display: flexbox !important;
                padding: 2%;
                background-color: #F4F4F4;
                margin-block-end: 1rem;

                h4,
                span {
                    text-align: left;
                }

                h5 {
                    padding-block-start: 2rem;
                }

                img {
                    background-color: #40DDB6;
                    padding: 0.4rem;
                    border-radius: 50%;
                }
            }

            .more {
                width: 32.5%;
                padding: 4%;
                background-color: #6B77E5;
                margin-block-end: 1rem;
            }
        }
    }

}