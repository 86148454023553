.aboutBannerContainer {
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }


    .aboutBanner {
        width: 45%;
        position: absolute;
        top: 0%;
        left: 0%;
        height: 99%;
        background-color: #98A2B3;
        opacity: 0.9;

        >div {
            position: absolute;
            width: fit-content;
            height: fit-content;
            top: 50%;
            left: 42%;
            transform: translate(-42%, -50%);
            color: #ffffff;
            // opacity: unset !important;

        }
    }
}

@media screen and (max-width:480px) {
    .aboutBannerContainer {

        img {
            height: 12rem;
        }

        .aboutBanner {
            width: 100%;
            position: absolute;
            top: 0%;
            left: 0%;
            height: 12rem;
            background-color: #98A2B3;
            opacity: 0.9;

            >div {
                position: absolute;
                width: fit-content;
                height: fit-content;
                padding-inline: 10%;
                top: 10%;
                left: 0%;
                transform: translate(0%, 0%);
                color: #ffffff;
                text-align: center;
            }
        }
    }

}