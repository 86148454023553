.recordsContainer {
    display: flex;
    justify-content: space-between;
    padding-inline: 8%;
    background-color: #8B9DA7;

    .card {
        width: 24.5%;
        text-align: center;
        padding-block: 2.25%;
        color: #ffffff;
    }
}

@media screen and (max-width:480px) {
    .recordsContainer {
        display: flex;
        justify-content: space-between;
        padding-inline: 8%;
        background-color: #8B9DA7;
        margin-block-start: 5%;

        .card {
            width: 50%;
            text-align: center;
            padding-block: 6.25%;
            color: #ffffff;
        }
    }
}