.headerContainer {
    padding-inline: 30%;
    display: flex;
    justify-content: space-between;
    background-color: #8EA0AA;

    p {
        color: #ffffff;
        padding-block: 2.5%;
        cursor: pointer;
    }
}