.serviceSectionContainer {
    padding-block: 5%;
    display: flex;
    justify-content: space-between;


    .leftSide {
        display: flex;
        justify-content: right;
        width: 45%;

        >div {
            padding-inline-end: 10%;

            .services {
                background-color: #F4F4F4;
                padding: 5%;
                height: fit-content;
                width: 20rem;

                >div {
                    display: flex;
                    justify-content: space-between;
                    padding: 3%;
                    cursor: pointer;

                    &:hover {
                        background-color: #6B77E5;

                        h5,
                        svg {
                            color: #ffffff !important;
                        }
                    }

                    p {
                        margin-block: auto;

                    }

                    svg {
                        margin-block: auto;
                    }
                }
            }

            .download {
                padding: 5%;
                margin-block: 8%;
                background-color: #F4F4F4;
                width: 20rem;

                >div {
                    display: flex;
                    background-color: #6B77E5;
                    color: #ffffff;
                    padding: 2%;
                    width: 10rem;
                    margin-block: 1rem;
                    cursor: pointer;

                    svg {
                        margin-inline-start: 1.15rem;
                        margin-inline-end: 0.4rem;
                    }

                    h5 {
                        margin-block: auto;
                    }
                }
            }

            .haveQuation {
                width: 20rem;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                >div {
                    text-align: center;
                    position: absolute;
                    width: 15rem;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    >div {
                        h4 {
                            font-size: 2rem;
                            font-weight: 700;
                            color: #37393F;
                            margin-block-start: 0.5rem;
                        }

                        p {
                            display: none;
                        }
                    }

                    >div:nth-last-child(1) {
                        margin-block-start: 1rem;

                        button {
                            background-color: #6B77E5;
                            color: #ffffff;
                            border-radius: unset;
                        }
                    }



                }
            }
        }
    }

    .rightSide {
        width: 55%;

        .servicesDetails {
            width: 70%;

            img {
                width: 100%;
                height: 100%;
                object-fit: fill;
            }

            .ourBenefits {
                display: flex;
                justify-content: space-between;
                margin-block-start: 5%;

                >div {
                    width: 49%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    >div {
                        >div {
                            display: flex;
                            margin-block: 0.8rem;

                            svg {
                                margin-block: auto;
                                border-radius: 50%;
                                padding: 0.10rem;
                                margin-inline-end: 0.5rem;
                            }
                        }
                    }
                }
            }
        }

        .servicesDetails+div {
            width: 75%;
            margin-block-start: 2rem;
        }
    }
}

@media screen and (max-width:480px) {
    .serviceSectionContainer {
        display: flex !important;
        flex-direction: column !important;

        .leftSide {
            width: 100%;

            >div {
                padding-inline: 10%;

                .services,
                .download,
                .haveQuation {
                    width: 100%;
                }
            }

        }

        .rightSide {
            width: 100%;

            .servicesDetails {
                width: 100%;
                padding-inline: 10%;
                margin-block: 5%;

                .ourBenefits {
                    display: flex;
                    flex-direction: column !important;

                    >div {
                        width: 100%;
                    }

                    >div:nth-child(2) {
                        margin-block: 5%;
                    }
                }
            }

            .servicesDetails+div {
                width: 100%;
                margin-block: 5%;
                padding-inline: 10%;
            }
        }
    }

}