@media screen and (max-width:480px) {
    .responseHeaderContainer {
        position: relative;

        >div {
            display: flex !important;
            justify-content: space-between;
            padding: 2%;
            svg{
                margin-block: auto !important;

            }


            p {
                width: fit-content;
            }
        }

        ul {
            background-color: #899BA9;
            color: #ffffff;
            position: absolute;
            top: 100%;
            left: 0%;
            width: 100%;
            z-index: 1;

            li {
                padding: 0;
                border-bottom: 1px solid #e3e3e3;
            }

        }
    }

}