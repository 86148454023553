.simpleAccordionContainr {
    >div:nth-child(1) {
        p {
            width: 100%;
            color: #7D7D7D;
            font-size: 1rem;
            border-bottom: unset;
            margin-block: 1rem;
        }
    }

}