.topbarContainer {
    padding-inline: 8%;
    // padding-block: 1%;
    display: flex;
    justify-content: space-between;

    .brand {
        margin-block: auto;
    }

    .contatc {
        display: flex;
        justify-content: space-between;

        .contatcInfo {
            display: flex;
            margin-block: auto;


            >div:nth-child(2) {
                padding-inline-start: 1rem;
            }
        }
    }
}