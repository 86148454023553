.bannerContainer {
    position: relative;

    .img {
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }
    }

    .opecity {
        background-color: #000000;
        position: absolute;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        opacity: 0.1;
    }

    .desc {
        width: 32rem;
        position: absolute;
        top: 50%;
        left: 11%;
        transform: translate(11%, -50%);
        color: #ffffff;

        p {
            padding-block: 0.6rem;
        }

        >div {
            margin-block-start: 1rem;
        }
    }
}

@media screen and (max-width:480px) {
    .bannerContainer {
        position: relative;

        .img {
            img {
                width: 100%;
                height: 12rem;
                object-fit: fill;
            }
        }

        .opecity {
            background-color: #000000;
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 12rem;
            opacity: 0.1;
        }

        .desc {
            width: 100%;
            height: 12rem;
            position: absolute;
            top: 42%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: #ffffff;
            padding-block-start: 6%;
            padding-inline: 5%;

            h4 {
                font-size: 1.8rem;
                text-align: center;
            }

            p {
                display: none;
            }

            >div {
                display: flex;
                justify-content: center;
                margin-block-start: 1rem;
            }
        }
    }

}