.footerContainer {
    background-color: #899BA9;
    padding-inline: 8%;
    display: flex;
    color: #ffffff;
    justify-content: space-between;

    >div {
        padding-block: 2rem;
        width: 24.5rem;

        h4 {
            margin-block-end: 1.25rem;
        }
    }

    .links {

        .linksSection {
            display: flex;
            justify-content: space-between;

        }
    }

    .social {
        .subscribe {
            display: flex;
            background-color: #F05836;
            margin-block: 1rem;

            >div {
                width: 100%;
                background-color: #ffffff;

                >div {
                    height: 2.5rem;
                }
            }

            svg {
                margin-block: auto;
                color: #ffffff;
                padding-inline: 1rem;
            }
        }
    }
}

@media screen and (max-width:480px) {
    .footerContainer {
        padding-inline: 14%;

        >div:nth-child(1) {
            padding-inline: 0%;
        }
    }

}