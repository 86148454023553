.letsTalkContainer {
    padding-inline: 14%;
    display: flex;
    justify-content: space-between;
    padding-block: 5%;

    >div {
        width: 47%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        >div {
            display: flex;
            margin-block: 2rem;

            svg {
                color: #6B77E5;
                background-color: #F4F4F4;
                padding: 0.6rem;
                border-radius: 50%;
                margin-inline-end: 0.5rem;
            }
        }
    }
}

@media screen and (max-width:480px) {
    .letsTalkContainer {
        >div:nth-child(1) {
            display: none;
        }

        >div:nth-child(2) {
            display: block;
            width: 100%;
        }
    }

}