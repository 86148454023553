.ourClientsContainer {
    >div:nth-child(1) {
        text-align: center;

        h4 {
            color: #101828;
            font-size: 2rem;
            margin-block: 0.8rem;
        }

        p {
            border-bottom: unset;
            text-align: center;
            width: 100%;
            color: #667085;
        }
    }

    .ourClients {
        background-color: #F8F8FF;
        margin-block: 5%;
        position: relative;
        height: 15rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }

        .opecity {
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            background-color: #F8F8FF;
            opacity: 0.97;
        }

        .clients {
            position: absolute;
            display: flex;
            justify-content: space-between;
            padding-inline: 8%;
            top: 50%;
            width: 100%;
            transform: translate(0%, -50%);

            img {
                width: 12rem;
                height: 6rem;
                margin-block: auto;
            }
        }
    }

}

@media screen and (max-width:480px) {
    .ourClientsContainer {

        >div:nth-child(1) {
            padding-inline: 10%;
        }

        .ourClients {
            background-color: #F8F8FF;
            margin-block: 5%;
            position: relative;
            height: 45rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: fill;
                display: none;
            }

            .opecity {
                position: absolute;
                top: 0%;
                left: 0%;
                width: 100%;
                height: 100%;
                background-color: #F8F8FF;
                opacity: 0.97;
            }

            .clients {
                position: absolute;
                display: flex !important;
                flex-direction: column !important;
                padding-inline: 8%;
                top: 50%;
                width: 100%;
                transform: translate(0%, -50%);


                img {
                    width: fit-content;
                    display: block;
                    margin: auto;
                    margin-block: 1rem;
                }
                img:nth-child(1),
                img:nth-child(2),
                img:nth-child(3),
                img:nth-child(4){
                    width: 55%;
                    height: 4rem;
                }
            }
        }
    }

}