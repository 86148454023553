.workProcessContainer {
    padding-block: 5%;
    padding-inline: 8%;

    >div:nth-child(1) {
        text-align: center;
        margin-block-end: 5rem;

        h4 {
            margin-block: 0.8rem;
            color: #37393F;
            font-size: 2rem;
        }

        p {
            display: none;
        }
    }

    .cardContainer {
        display: flex;
        justify-content: space-between;
        margin-block: 3.5rem;

        .card {
            width: 32.5%;
            background-color: #F4F4F4;
            padding: 4%;
            padding-block: 2%;
            text-align: center;
            // position: relative;

            img {
                // background-color: #40DDB6;
                color: #ffffff;
                // padding: 2.5%;
                // border-radius: 50%;
                width: 2.8rem;
                height: 2.8rem;
                // position: absolute;
                // bottom: 86%;
                // left: 43%;
            }
        }
    }
}

@media screen and (max-width:480px) {
    .workProcessContainer {
        >div:nth-child(1) {
            margin-block-start: 2rem;
            margin-block-end: 2rem;
        }

        .cardContainer {
            display: flex !important;
            flex-direction: column !important;
            margin: 0;

            .card {
                width: 100% !important;
                margin-block: 2rem;
                padding: 6%;

                h4 {
                    padding-block-start: 1rem;
                }
            }
        }
    }

}