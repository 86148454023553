.desclaimer-margin{
    height: "1000px";
     padding-top: "30px";
     border-top:  white solid 30px;
     border-bottom:  white solid 30px;

    
}

.disclaimer-page {
    text-align: center;
    margin-top: "50px";
 margin-bottom: "50px";
    font-family: Arial, sans-serif;
  
    .disclaimer-header {
      background-color: #899BA9;
      padding: 50px 0;
  
      h1 {
        color: #fff;
        font-size: 48px;
        margin: 0;
      }
    }
  
    .disclaimer-content {
      margin: 50px 20px;
      font-size: 16px;
      color: #333;
      display: flex;
      flex-direction: column; 
  
      h2 {
        margin-top: 30px;
        color: #000000;
        margin-left: "100px";
      }
    }
  
    .subscribe-section {
      background-color: #899BA9;
      padding: 50px 20px;
      color: #fff;
  
      h2 {
        font-size: 32px;
        margin-bottom: 20px;
      }
  
      .subscribe-form {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
  
        input {
          padding: 10px 20px;
          font-size: 16px;
          border: none;
          border-radius: 50px 0 0 50px;
          width: 300px;
          max-width: 100%;
        }
  
        button {
          padding: 10px 30px;
          font-size: 16px;
          border: none;
          border-radius: 0 50px 50px 0;
          background-color: #899BA9;;
          color: #fff;
          cursor: pointer;
  
          &:hover {
            background-color: #7f8d97;          }
        }
      }
  
      .note {
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
  