.appContainer {

    >div:nth-child(3) {
        display: none;
    }
}

@media screen and (max-width:480px) {
    .appContainer {

        >div:nth-child(1),
        >div:nth-child(2) {
            display: none;
        }

        >div:nth-child(3) {
            display: block;
        }
    }

}