.recruitFormContainer {
    padding-inline: 15%;
    padding-block-end: 5%;

    >div:nth-child(1) {
        text-align: center;

        h4 {
            color: #101828;
            font-size: 2rem;
            margin-block: 0.8rem;
        }

        p {
            border-bottom: unset;
            text-align: center;
            width: 100%;
            color: #667085;
        }
    }

    .recruitForm {
        display: flex;
        justify-content: space-between;
        width: 100%;

        >div {
            width: 49%;

            p {
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                margin-block: 2rem;
                color: #667085;
                padding: 5.6%;
            }
        }
    }

    // >div:nth-last-child(1){
    //     display: flex;
    //     justify-content: center;
    //     margin-block-start: 1rem;
    //     button{
    //         background-color: #6B77E5;
    //         color: #ffffff;
    //         border-radius: unset;
    //     }
    // }
}

@media screen and (max-width:480px) {
    .recruitFormContainer {
        >div:nth-child(1) {
            margin-block-start: 2.5rem;
        }

        .recruitForm {
            display: flex;
            flex-direction: column !important;
            width: 100%;

            >div {
                width: 100%;

                p {
                    border: unset;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                    margin-block: 2rem;
                    padding: 5.6%;
                }
            }
        }

    }

}